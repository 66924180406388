import { lazy, Suspense } from 'react'

const HomeAnimatedLayout = lazy(() => import('./HomeAnimatedLayout'))

const SuspenseHomeAnimatedLayout = ({ locationKey, children }) => {
  return (
    <Suspense fallback={<div />}>
      <HomeAnimatedLayout locationKey={locationKey} children={children} />
    </Suspense>
  )
}

export default SuspenseHomeAnimatedLayout
