module.exports = [{
      plugin: require('../../../../libs/dce-openpages/gatsby/gatsby-plugin-prevent-initial-scroll-behavior/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../libs/dce-openpages/gatsby/gatsby-plugin-chatbot/gatsby-browser.js'),
      options: {"plugins":[],"chatFrameUrl":"https://chat.dnb.no/index.html?noHeader&noWelcome"},
    },{
      plugin: require('../../../../libs/dce-openpages/gatsby/gatsby-plugin-emotion-cache/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
