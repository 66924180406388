import React from 'react'
import { ChatBot } from '@dnb-web-static-libs/gatsby-plugin-chatbot'
import { isHomeTemplate } from 'utils/isHomeTemplate'
import { getLocaleByLanguage } from '@dnb-web-static-libs/intl'
import { defaultLanguage } from 'intl/getLocale'
import HomeAnimatedLayout from 'components/HomeAnimatedLayout'

if (typeof window === 'undefined') {
  // for SSR
  React.useLayoutEffect = React.useEffect
}

const HomePageTransitionWrapper = ({ children, props }) => {
  // we need special transition logic for home pages
  const template = props.data && props.data.aemPage && props.data.aemPage.template
  const isHomePage = template && isHomeTemplate(template)
  if (isHomePage) {
    const locationKey = props.location.key
    // Lazy load framer-motion logic
    return (
      <HomeAnimatedLayout locationKey={locationKey}>
        {children}
      </HomeAnimatedLayout>
    )
  }

  return children
}

const isCypressTestEnvironment =
  // Environment varible from Cypress for client side
  process.env.GATSBY_CYPRESS_ENV_TYPE === 'test'

/**
 * @type {import('gatsby').GatsbyBrowser['wrapPageElement']}
 */
export const wrapPageElement = ({ element, props }) => {
  const language = props.pageContext.language
  // We do not want to have ChatBot and HomeTransition for Cypress Test environment
  const content = isCypressTestEnvironment ? (
    element
  ) : (
    <>
      <HomePageTransitionWrapper props={props}>{element}</HomePageTransitionWrapper>
      <ChatBot
        locale={getLocaleByLanguage(language)}
        defaultLocale={getLocaleByLanguage(defaultLanguage)}
      />
    </>
  )
  return content
}
